<template>
  <div class="risk-warning">
    <div class="pad15 sctp-bg-white">
      <div class="slider-card-title">
        交易风险提示
      </div>
    </div>
    <div class="pad15 sctp-bg-white flex flex-sb">
      <div class="" style="background-color:#f2f2f2;padding: 30px 45px;">
        <div class="fz-16" style="color: #333">提醒：您还未签署交易风险提示单！</div>
        <div class="pad-t20 fc-info">请仔细阅读以下网上交易风险提示内容，我们严肃的提醒您网上交易的可能风险，了解并避免风险交易，让您
          的资金不受损失，如您忽略风险、抱有侥幸心理、自以为是都将可能遭受财产损失，我们慎重的提醒您尽量选择担保交易。
        </div>
        <el-upload
          :http-request="fileUploadFunction"
          :data="{...uploadConfig.uploadEnum.USER}"
          action=""
          :show-file-list="false"
          :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'riskWarning'})"
          :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'riskWarning'})">
          <el-button class="mg-t15" size="small">上传风险提示单</el-button>
        </el-upload>
        <div class="el-upload__tip">注：阅读并打印下面风险提示单，个人签字、企业/个体工商户盖公章，仅限jpg/png文件，文件大小1M以内</div>
      </div>
      <div style="width: 600px;" class="flex flex-col flex-center">
        <el-image
          v-if="riskWarningConfig.preview"
          :src="riskWarningConfig.preview"
          fit="cover"
          style="width: 141px;height: 200px;"
        ></el-image>
        <div v-else
             class="flex flex-center"
             style="width: 141px;height: 200px;border: 1px #7d7d7d dashed;"
        >
          <i class="el-icon-plus"></i>
        </div>
        <div class="sctp-tc" v-if="riskWarningConfig.authFlag || riskWarningConfig.authFlag === 0">
          <template v-if="riskWarningConfig.authFlag === 0">
            <i class="el-icon-warning fz-20"></i>
            <div>待审核</div>
          </template>
          <template v-if="riskWarningConfig.authFlag === 1">
            <i class="el-icon-success fz-20 sctp-color-main"></i>
            <div>已通过</div>
          </template>
          <template v-if="riskWarningConfig.authFlag === 2">
            <i class="el-icon-error fz-20" style="color: #F56C6C;"></i>
            <div>不通过:{{ riskWarningConfig.authMemo }}</div>
          </template>
        </div>
      </div>
    </div>
    <div class="pad15 sctp-bg-white mg-t10">
      <div style="width: 80%;margin: auto;">
        <div ref="riskWarningContent" class="pad-tb20 pad-lr15">
          <h1 class="sctp-tc fz-26">网上交易风险提示确认单</h1>
          <div>
            <div v-html="riskWarningConfig.content"
                 class="rich-text"></div>
          </div>
        </div>
        <div class="flex flex-center">
          <el-button @click="riskWarningConfig.onPrintClick" type="primary" size="small">打印
          </el-button>
          <el-button @click="riskWarningConfig.onDownloadClick" size="small">下载</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {common, user} from "@/apis/index";
import html2canvas from 'html2canvas';
import jsPDF from '@/resources/js/jspdf.debug';
import uploadEnum from "@/resources/js/uploadenum";

export default {
  name: "riskWarning",
  data() {
    return {
      riskWarningConfig: {
        content: null,
        imgUrl: '',
        preview: '',
        authFlag: null,
        authMemo: '',
        loadContent: () => {
          common.loadProtocol({
            protocolName: '交易风险提示'
          }).then(res => {
            let {retdata} = res;
            this.riskWarningConfig.content = retdata;
          })
        },
        loadRiskWarning: () => {
          user.getRiskWarning({
            userId: this.user.userId
          }).then(res => {
            let {retdata: {isSignRiskWarning, riskWarningImgUrl, riskWarningAuthFlag, riskWarningAuthMemo}} = res;
            this.riskWarningConfig.authFlag = riskWarningImgUrl && riskWarningAuthFlag;
            this.riskWarningConfig.preview = riskWarningImgUrl;
            this.riskWarningConfig.authMemo = riskWarningAuthMemo;
          })
        },
        onSubmitClick: () => {
          let {riskWarningConfig: {imgUrl}} = this;
          if (!imgUrl) {
            this.$message.warning("请先 下载/打印 协议签字之后拍照上传.")
            return;
          }
          user.submitRiskWarningImg({
            userId: this.user.userId,
            imgUrl: imgUrl
          }).then(() => {
            this.riskWarningConfig.loadRiskWarning();
          })
        },
        downPdf: () => {
          document.documentElement.scrollTop = 0
          let canvas = document.createElement("canvas")
          let context = canvas.getContext("2d")
          let element = this.$refs['riskWarningContent'];
          let elementWidth = element.clientWidth;
          let elementHeight = element.clientHeight;
          let scale = 3;
          canvas.width = elementWidth * scale;
          canvas.height = elementHeight * scale;
          context.scale(scale, scale);
          let opts = {
            scale: 1,
            width: elementWidth,
            height: elementHeight,
            canvas: canvas,
            useCORS: true
          }
          html2canvas(element, opts).then(canvas => {
            this.riskWarningConfig.createPdfAll(canvas, scale)
          })
        },
        createPdfAll(canvas, scale) {
          let contentWidth = canvas.width / scale
          let contentHeight = canvas.height / scale
          let pdf = new jsPDF('portrait', 'pt', [contentWidth, contentHeight])
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          pdf.addImage(pageData, 'JPEG', 0, 0, contentWidth, contentHeight);
          pdf.save('风险交易确认单' + '.pdf');
        },
        onPrintClick: () => {
          let iframe = document.getElementById("print-iframe");
          if (!iframe) {
            let el = this.$refs['riskWarningContent'];
            iframe = document.createElement('IFRAME');
            let doc;
            iframe.setAttribute("id", "print-iframe");
            iframe.setAttribute('style',
              'position:absolute;width:0px;height:0px;left:-500px;top:-500px;');
            document.body.appendChild(iframe);
            doc = iframe.contentWindow.document;
            doc.write('<div>' + el.innerHTML + '</div>');
            doc.close();
            iframe.contentWindow.focus();
          }
          iframe.contentWindow.print();
          if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
          }
        },
        onDownloadClick: () => {
          this.riskWarningConfig.downPdf();
        },
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'riskWarning') {
            this.riskWarningConfig.imgUrl = response.src;
            this.riskWarningConfig.preview = response.preview;
          }
          this.$message.success('文件上传成功！');
          this.riskWarningConfig.onSubmitClick();
        },
        onRemove: ({key}, file, fileList) => {
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (['riskWarning'].includes(key)) {
            const allowType = [
              'image/jpeg',
              'image/png'
            ];
            const isMatchType =
              allowType.some(type => file.type.startsWith(type))
            ;
            const isLtSize = file.size / 1024 / 1024 < 1;
            if (!isMatchType) {
              this.$message.error('上传文件只能是 图片 格式!');
              return false;
            }
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 1MB!`);
              return false;
            }
            result = isMatchType && isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  beforeMount() {
    this.riskWarningConfig.loadContent();
    this.riskWarningConfig.loadRiskWarning();
  }
}
</script>

<style scoped lang="scss">
.risk-warning {
}
</style>
